<template>
  <div class="recruit">
    <section-title :titleInfo="firstPartTitleInfo"></section-title>
    <div class="info1" v-if="info1Flag == '1'">
      <div class="info1Title">
        <div class="titleIcon"></div>
        软件开发工程师
      </div>
      <div class="wantNum">
        <div class="wNLeft">
          <div class="wNLDiv1">招聘对象：全职</div>
          <div class="wNLDiv1">招聘人数：3</div>
          <div>发布时间：2024-07-03</div>
        </div>
        <div class="wNRight">
          <i class="el-icon-arrow-down" @click="info1Flag = '2'"></i>
        </div>
      </div>
      <div class="line"></div>
      <div class="jobDec">
        <div class="jobDecLeft">
          <div class="leftTitle">
            <div class="leftTitleIcon"></div>
            职位描述
          </div>
          <div class="titleDec">1、完成软件系统代码的实现，编写代码注释和开发文档；</div>
          <div class="titleDec">2、辅助进行系统的功能定义，程序设计；</div>
          <div class="titleDec">3、根据设计文档或需求说明完成代码编写，调试，测试和维护；</div>
          <div class="titleDec">4、分析并解决软件开发过程中的问题；</div>
          <div class="titleDec">5、协助测试工程师制定测试计划，定位发现的问题；</div>
          <div class="titleDec">6、配合产品经理完成相关任务目标；</div>
          <div class="titleDec">7、编制产品总方案设计说明书；</div>
          <div class="titleDec">8、完成领导交办的其它事宜。</div>
        </div>
        <div class="jobDecRight">
          <div class="leftTitle">
            <div class="leftTitleIcon"></div>
            职位要求
          </div>
          <div class="titleDec">1、本科以上学历，计算机、通信、电力、自动化、信息安全等相关专业；</div>
          <div class="titleDec">2、有较深的JAVA基础，熟悉IO、多线程、反射技术，熟练使用主流开源应用框架，并了解其原理和实现机制，如Spring/Spring、MVC/Spring Boot、Hibernate、MyBatis等；</div>
          <div class="titleDec">3、熟悉各种Web前端技术和主流框架（vue，jQuery等），熟练使用各种工具检测web服务的性能和定位瓶颈，具有对CSS/JavaScript性能优化、解决多浏览器兼容性的经验，对交互体验、可用性、用户体验有深刻的理解；</div>
          <div class="titleDec">
            4、熟练掌握面向对象设计，能够熟练使用SQL数据库、消息服务等解决实际问题，熟悉分布式系统开发；
          </div>
          <div class="titleDec">5、熟练使用Maven、Git等项目开发工具，熟悉容器化部署；</div>
          <div class="titleDec">
            6、具备良好的编程习惯，良好的学习能力和钻研精神，有较强的分析问题和解决问题的能力，乐于接受新鲜技术；
          </div>
          <div class="titleDec">7、工作积极主动，具有强烈的责任感、团队合作精神和严谨的工作作风。</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="btns">
        <div class="btnLeft">
          <!-- 我要申请 -->
        </div>
        <div class="btnRight">
          <div class="letter"></div>
          简历投递邮箱：
          <div class="blue">zhirongtechnology@163.com</div>
        </div>
      </div>
    </div>
    <div class="info1A" v-else-if="info1Flag == '2'">
      <div class="info1Title">
        <div class="titleIcon"></div>
        软件开发工程师
      </div>
      <div class="wantNum">
        <div class="wNLeft">
          <div class="wNLDiv1">工作地点：南京</div>
          <div class="wNLDiv1">招聘对象：全职</div>
          <div class="wNLDiv1">招聘人数：3</div>
          <div>发布时间：2024-07-03</div>
        </div>
        <div class="wNRight">
          <i class="el-icon-arrow-right" @click="info1Flag = '1'"></i>
        </div>
      </div>
    </div>
    <div class="info2" v-if="info2Flag == '1'">
      <div class="info1Title">
        <div class="titleIcon"></div>
        嵌入式软件开发工程师
      </div>
      <div class="wantNum">
        <div class="wNLeft">
          <div class="wNLDiv1">工作地点：南京</div>
          <div class="wNLDiv1">招聘对象：全职</div>
          <div class="wNLDiv1">招聘人数：1</div>
          <div>发布时间：2024-07-03</div>
        </div>
        <div class="wNRight">
          <i class="el-icon-arrow-right" @click="info2Flag = '2'"></i>
        </div>
      </div>
    </div>
    <div class="info2A" v-else-if="info2Flag == '2'">
      <div class="info1Title">
        <div class="titleIcon"></div>
        嵌入式软件开发工程师
      </div>
      <div class="wantNum">
        <div class="wNLeft">
          <div class="wNLDiv1">招聘对象：全职</div>
          <div class="wNLDiv1">招聘人数：1</div>
          <div>发布时间：2024-07-03</div>
        </div>
        <div class="wNRight">
          <i class="el-icon-arrow-down" @click="info2Flag = '1'"></i>
        </div>
      </div>
      <div class="line"></div>
      <div class="jobDec">
        <div class="jobDecLeft">
          <div class="leftTitle">
            <div class="leftTitleIcon"></div>
            职位描述
          </div>
          <div class="titleDec">1、负责新产品和迭代产品的技术调研，参与实施产品研发任务，并按目标与计划完成；</div>
          <div class="titleDec">2、根据项目产品需求编写产品需求说明书、总体设计方案等文件；</div>
          <div class="titleDec">3、解决开发过程中的技术问题；</div>
          <div class="titleDec">4、制动验证方案、分析总结验证结果；</div>
          <div class="titleDec">5、协同工程人员完成系统的调试和交付工作；</div>
          <div class="titleDec">6、完成领导交办的其他工作。</div>
        </div>
        <div class="jobDecRight">
          <div class="leftTitle">
            <div class="leftTitleIcon"></div>
            职位要求
          </div>
          <div class="titleDec">1、计算机、软件、电子、通信、自动化等相关专业研究生学历,优秀的应届生亦可；</div>
          <div class="titleDec">2、嵌入式系统开发相关工作经验，精通C/C++语言等编程语言，熟悉面向对象设计和编程，具备独立承担项目开发能力；</div>
          <div class="titleDec">3、熟悉QT集成开发环境，熟悉Linux等嵌入式系统，熟悉嵌入式Linux移植方法、Linux指令；</div>
          <div class="titleDec">
            4、了解多进程、多线程并发环境编程，以及进程间通信；
          </div>
          <div class="titleDec">5、熟悉101、104、61850等电力系统通讯规约优先；</div>
          <div class="titleDec">6、有良好的代码编写习惯，良好的沟通协作能力及团队合作精神，具有较强的抗压能力。</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="btns">
        <div class="btnLeft">
          <!-- 我要申请 -->
        </div>
        <div class="btnRight">
          <div class="letter"></div>
          简历投递邮箱：
          <div class="blue">zhirongtechnology@163.com</div>
        </div>
      </div>
    </div>
    <div class="info2" v-if="info3Flag == '1'">
      <div class="info1Title">
        <div class="titleIcon"></div>
        主办会计
      </div>
      <div class="wantNum">
        <div class="wNLeft">
          <div class="wNLDiv1">工作地点：南京</div>
          <div class="wNLDiv1">招聘对象：全职</div>
          <div class="wNLDiv1">招聘人数：1</div>
          <div>发布时间：2024-07-03</div>
        </div>
        <div class="wNRight">
          <i class="el-icon-arrow-right" @click="info3Flag = '2'"></i>
        </div>
      </div>
    </div>
    <div class="info2A" v-else-if="info3Flag == '2'">
      <div class="info1Title">
        <div class="titleIcon"></div>
        主办会计
      </div>
      <div class="wantNum">
        <div class="wNLeft">
          <div class="wNLDiv1">招聘对象：全职</div>
          <div class="wNLDiv1">招聘人数：1</div>
          <div>发布时间：2024-07-03</div>
        </div>
        <div class="wNRight">
          <i class="el-icon-arrow-down" @click="info3Flag = '1'"></i>
        </div>
      </div>
      <div class="line"></div>
      <div class="jobDec">
        <div class="jobDecLeft">
          <div class="leftTitle">
            <div class="leftTitleIcon"></div>
            职位描述
          </div>
          <div class="titleDec">1、在财务总监、财务经理领导下，执行各经营主体、事业部的账务核算和内、外部报告工作；</div>
          <div class="titleDec">2、监督反馈内控程序的运行，跟踪、反馈预算的执行；及时与相关部门沟通反馈，向上级请示解决方案，并推进问题解决和流程改进；</div>
          <div class="titleDec">3、复核成本/往来业务，复核企业的供应商付款和客户收款情况；</div>
          <div class="titleDec">4、对出纳岗位工作进行日常复核；</div>
          <div class="titleDec">5、核算、监督企业的资金流向，报告、分析各项目、收入、费用和成本发生情况，跟踪、分析与比较差异并报告；</div>
          <div class="titleDec">6、定期编制本人负责的各项对内对外周报、月报、季报、年报；定期复核出纳负责的各项对内对外日报、周报、月报、季报、年报；</div>
          <div class="titleDec">7、在上级的指导和其他岗位的配合下，执行预算的跟踪、核算、报告；</div>
          <div class="titleDec">8、随时完成上级领导交办的其它临时性工作事项，随时接受工作检查。</div>
        </div>
        <div class="jobDecRight">
          <div class="leftTitle">
            <div class="leftTitleIcon"></div>
            职位要求
          </div>
          <div class="titleDec">1、财经类专业本科及以上学历，从事财务工作3年以上，核算岗位工作2年以上；</div>
          <div class="titleDec">2、全面的企业财务、银行业务及较全面的税务工作常识；</div>
          <div class="titleDec">3、具备良好跨部门沟通与协调能力；</div>
          <div class="titleDec">
            4、熟练使用金蝶或用友ERP软件，熟练使用网络及主流办公自动化软件，熟练使用计算机及各种办公自动化设备；
          </div>
          <div class="titleDec">5、会计初级职称、中级以上职称优先。</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="btns">
        <div class="btnLeft">
          <!-- 我要申请 -->
        </div>
        <div class="btnRight">
          <div class="letter"></div>
          简历投递邮箱：
          <div class="blue">zhirongtechnology@163.com</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: { sectionTitle },
  data() {
    return {
      firstPartTitleInfo: {
        bigtext1: "招聘职位",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      info1Flag: 1,
      info2Flag: 1,
      info3Flag: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.recruit {
  width: 100%;
  padding: 160px 170px;
  box-sizing: border-box;
  .info1 {
    padding: 45px 58px;
    box-sizing: border-box;
    margin-bottom: 54px;
    width: 100%;
    //height: 615px;
    background: #ffffff;
    box-shadow: 10px 0px 20px 1px rgba(29, 29, 29, 0.12);
    border-radius: 10px 10px 10px 10px;
    .info1Title {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .titleIcon {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        background: url("../images/icon1.png") no-repeat;
        background-size: 100% 100%;
      }
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 32px;
      color: #1f63ea;
    }
    .wantNum {
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: 30px;
      box-sizing: border-box;
      .wNLeft {
        display: flex;
        justify-content: flex-end;
        width: 50%;
        .wNLDiv1 {
          margin-right: 60px;
        }
      }
      .wNRight {
        width: 50%;
        text-align: right;
        font-size: 30px;
      }
      // border-bottom: 1px dashed #000;
    }
    .jobDec {
      display: flex;
      width: 100%;
      padding: 50px;
      box-sizing: border-box;
      // border-bottom: 1px dashed #000;
      margin-bottom: 20px;
      .jobDecLeft {
        width: 40%;
        box-sizing: border-box;
        padding: 0px 10px;
        .leftTitle {
          display: flex;
          align-items: center;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          font-size: 26px;
          margin-bottom: 15px;
          color: #1d1d1d;
          .leftTitleIcon {
            width: 5px;
            height: 24px;
            background: url("../images/infoIcon.png") no-repeat;
            background-size: 100% 100%;
            margin-right: 10px;
          }
        }
        .titleDec {
          margin-bottom: 10px;
        }
      }
      .jobDecRight {
        width: 60%;
        box-sizing: border-box;
        padding: 0px 10px;
        .leftTitle {
          display: flex;
          align-items: center;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          font-size: 26px;
          margin-bottom: 15px;
          color: #1d1d1d;
          .leftTitleIcon {
            width: 5px;
            height: 24px;
            background: url("../images/infoIcon.png") no-repeat;
            background-size: 100% 100%;
            margin-right: 10px;
          }
        }
        .titleDec {
          margin-bottom: 10px;
        }
      }
    }
    .btns {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .btnLeft {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 58px;
        // background-color: #1f63ea;
        // border-radius: 29px;
        // color: white;
      }
      .btnRight {
        display: flex;
        padding-top: 30px;
        box-sizing: border-box;
        align-items: center;
        .letter {
          width: 32px;
          height: 32px;
          margin-right: 10px;
          background: url("../images/letter.png") no-repeat;
          background-size: 100% 100%;
        }
        .blue {
          color: #1f63ea;
        }
      }
    }
  }
  .info1A {
    width: 100%;
    height: 182px;
    background-color: #f6f6f6;
    margin-bottom: 54px;
    padding: 45px 55px;
    box-sizing: border-box;
    .info1Title {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .titleIcon {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        background: url("../images/icon1.png") no-repeat;
        background-size: 100% 100%;
      }
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 32px;
      color: #1f63ea;
    }
    .wantNum {
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: 30px;
      box-sizing: border-box;
      .wNLeft {
        display: flex;
        padding-left: 30px;
        box-sizing: border-box;
        width: 60%;
        .wNLDiv1 {
          margin-right: 60px;
        }
      }
      .wNRight {
        width: 40%;
        text-align: right;
        font-size: 30px;
      }
    }
  }
  .info2 {
    width: 100%;
    height: 182px;
    background-color: #f6f6f6;
    margin-bottom: 54px;
    padding: 45px 55px;
    box-sizing: border-box;
    .info1Title {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .titleIcon {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        background: url("../images/icon1.png") no-repeat;
        background-size: 100% 100%;
      }
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 32px;
      color: #1f63ea;
    }
    .wantNum {
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: 30px;
      box-sizing: border-box;
      .wNLeft {
        display: flex;
        padding-left: 30px;
        box-sizing: border-box;
        width: 60%;
        .wNLDiv1 {
          margin-right: 60px;
        }
      }
      .wNRight {
        width: 40%;
        text-align: right;
        font-size: 30px;
      }
    }
  }
  .info2A {
    padding: 45px 58px;
    box-sizing: border-box;
    margin-bottom: 54px;
    width: 100%;
    //height: 615px;
    background: #ffffff;
    box-shadow: 10px 0px 20px 1px rgba(29, 29, 29, 0.12);
    border-radius: 10px 10px 10px 10px;
    .info1Title {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .titleIcon {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        background: url("../images/icon1.png") no-repeat;
        background-size: 100% 100%;
      }
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 32px;
      color: #1f63ea;
    }
    .wantNum {
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: 30px;
      box-sizing: border-box;
      .wNLeft {
        display: flex;
        justify-content: flex-end;
        width: 50%;
        .wNLDiv1 {
          margin-right: 60px;
        }
      }
      .wNRight {
        width: 50%;
        text-align: right;
        font-size: 30px;
      }
      // border-bottom: 1px dashed #000;
    }
    .jobDec {
      display: flex;
      width: 100%;
      padding: 50px;
      box-sizing: border-box;
      // border-bottom: 1px dashed #000;
      margin-bottom: 20px;
      .jobDecLeft {
        width: 40%;
        box-sizing: border-box;
        padding: 0px 10px;
        .leftTitle {
          display: flex;
          align-items: center;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          font-size: 26px;
          margin-bottom: 15px;
          color: #1d1d1d;
          .leftTitleIcon {
            width: 5px;
            height: 24px;
            background: url("../images/infoIcon.png") no-repeat;
            background-size: 100% 100%;
            margin-right: 10px;
          }
        }
        .titleDec {
          margin-bottom: 10px;
        }
      }
      .jobDecRight {
        width: 60%;
        box-sizing: border-box;
        padding: 0px 10px;
        .leftTitle {
          display: flex;
          align-items: center;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          font-size: 26px;
          margin-bottom: 15px;
          color: #1d1d1d;
          .leftTitleIcon {
            width: 5px;
            height: 24px;
            background: url("../images/infoIcon.png") no-repeat;
            background-size: 100% 100%;
            margin-right: 10px;
          }
        }
        .titleDec {
          margin-bottom: 10px;
        }
      }
    }
    .btns {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .btnLeft {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 58px;
        // background-color: #1f63ea;
        // border-radius: 29px;
        // color: white;
      }
      .btnRight {
        display: flex;
        padding-top: 30px;
        box-sizing: border-box;
        align-items: center;
        .letter {
          width: 32px;
          height: 32px;
          margin-right: 10px;
          background: url("../images/letter.png") no-repeat;
          background-size: 100% 100%;
        }
        .blue {
          color: #1f63ea;
        }
      }
    }
  }
  .line {
    width: 100%;
    height: 2px;
    background: url("../images/line.png") no-repeat;
    background-size: 100% 100%;
  }
  .wNRight{
    i{
      cursor: pointer;
    }
  }
}
</style>