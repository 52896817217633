<template>
  <div class="section-title">
    <div class="title-logo">
      <img
        class="section-title-logo"
        src="@/assets/home/section-title-logo.png"
        alt=""
      />
    </div>
    <div
      class="title-big-text title-big-text1"
      :class="{ 'color-white': titleInfo.color }"
    >
      {{ titleInfo.bigtext1 }}
    </div>
    <div
      class="title-big-text title-big-text2"
      :class="{ 'color-white': titleInfo.color }"
      v-if="titleInfo.bigtext2"
    >
      {{ titleInfo.bigtext2 }}
    </div>
    <div class="title-small-text" :class="{ 'color-white': titleInfo.color }">
      {{ titleInfo.smallText }}
    </div>
    <div class="know-more" v-if="titleInfo.showKnowMore">
      <img
        class="item-arrow"
        src="@/assets/home/section-title-arrow.png"
        alt=""
      />
      <span>进一步了解</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  width: 100%;
  .title-logo {
    width: 100%;
    height: 30px;
    .section-title-logo {
      width: 58px;
      height: 30px;
    }
  }
  .title-big-text {
    font-weight: bold;
    font-size: 42px;
    color: #1d1d1d;
    margin-bottom: 30px;
  }
  .title-small-text {
    width: 700px;
    font-size: 18px;
    color: #7d7d7d;
    margin-bottom: 36px;
    line-height: 32px;
  }
  .know-more {
    width: 100%;
    display: flex;
    font-size: 20px;
    color: #1d1d1d;
    .item-arrow {
      width: 32px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .color-white {
    color: #fff;
  }
}
</style>


