<template>
  <div>
    <Nav></Nav>
    <banner-part :bannerInfo="bannerInfo"></banner-part>
    <!-- <div class="container">
      <tabs-section :defaultIndex="defaultIndex"></tabs-section>
    </div> -->
    <recruit></recruit>
    <Footer class="top" />
  </div>
</template>

<script>
import BannerPart from "../components/BannerPart.vue";
import TabsSection from "../components/tabsSection.vue";
import Recruit from "./components/recruit.vue";
export default {
  components: { BannerPart, TabsSection, Recruit },
  data() {
    return {
      bannerInfo: {
        text1: "人才招聘",
        text2: "做国内一流的智慧能源解决方案及服务提供商",
        swiperImg: require("./images/swiper.png"),
      },
      defaultIndex: "1",
    };
  },
  mounted() {
    this.defaultIndex = this.$route.params.index || "1";
    console.log("this.defaultIndex>>>", this.defaultIndex);
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 600px;
}
</style>